import axios from "../http";
import { AxiosResponse } from "axios";

export default class VideoService {
  static fetchVideo(clientName: string, id: string): Promise<AxiosResponse<any>> {
    return axios.get(`/share/${clientName}/${id}`, { bypassAuthorization: true });
  }

  static fetchVideoById(id: string): Promise<AxiosResponse<any>> {
    return axios.get(`/share/${id}`, { bypassAuthorization: true });
}

  static playVideo(id: string): Promise<AxiosResponse<any>> {
    return axios.post(`/play/${id}`, { bypassAuthorization: true });
  }

  static ctaClicked(id: string): Promise<AxiosResponse<any>> {
    return axios.post(`/cta/${id}`, { bypassAuthorization: true });
  }

  static watchedTime(id: string, seconds: number): Promise<AxiosResponse<any>> {
    return axios.post(
      `/watched-time/${id}`,
      { seconds },
      { bypassAuthorization: true }
    );
  }

  static dashboardDetails(id: string): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/${id}`);
  }

  static videoPlayed(id: string): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/videos-played/${id}`);
  }

  static dashboardCtaClicked(id: string): Promise<AxiosResponse<any>> {
    return axios.get(`/dashboard/cta-clicked/${id}`);
  }

  static resetOrganizationData(id: string): Promise<AxiosResponse<any>> {
    return axios.put(`/reset-organization/${id}`);
  }

  static organizationLogin(
    organizationName: string,
    password: string
  ): Promise<AxiosResponse<any>> {
    return axios.post(
      `/login`,
      { organizationName, password },
      { bypassAuthorization: true }
    );
  }
}
