import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
export const API_URL = process.env.REACT_APP_SERVER_URL;

declare module "axios" {
  export interface AxiosRequestConfig {
    bypassAuthorization?: boolean;
  }
}

axios.interceptors.request.use(
  (config: any) => {
    if (config.url) {
      config.url = API_URL + config.url;
    }
    const token = localStorage.getItem("authToken");
    if (token && !config.bypassAuthorization) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
export default axios;
