// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_card__7WVYK {\n  cursor: pointer;\n}\n.card_card__7WVYK:hover {\n  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.5);\n}\n\n.card_noHover__5tjH4 {\n  cursor: auto;\n}\n.card_noHover__5tjH4:hover {\n  box-shadow: auto;\n}\n\n.card_textRoot__-4wkY {\n  font-weight: 600 !important;\n  color: black;\n}\n\n.card_cardRoot__CFJgC {\n  background-image: linear-gradient(black) !important;\n  border: 1px solid rgba(0, 0, 0, 0);\n}\n\n.card_countUpTextRoot__sGdzt {\n  color: black;\n  font-family: Space Mono, sans-serif !important;\n}\n\n.card_cardContentRoot__a9Wff {\n  height: 100%;\n  font-family: Space Mono, sans-serif !important;\n  padding: 8px !important;\n}", "",{"version":3,"sources":["webpack://./src/components/card/card.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,qDAAA;AAEJ;;AAEA;EACE,YAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,2BAAA;EACA,YAAA;AACF;;AAEA;EACE,mDAAA;EACA,kCAAA;AACF;;AAEA;EACE,YAAA;EACA,8CAAA;AACF;;AAEA;EAEE,YAAA;EACA,8CAAA;EACA,uBAAA;AAAF","sourcesContent":[".card {\n  cursor: pointer;\n  &:hover {\n    box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.5);\n  }\n}\n\n.noHover {\n  cursor: auto;\n  &:hover {\n    box-shadow: auto;\n  }\n}\n\n.textRoot {\n  font-weight: 600 !important;\n  color: black;\n}\n\n.cardRoot {\n  background-image: linear-gradient(black) !important;\n  border: 1px solid rgba(0, 0, 0, 0);\n}\n\n.countUpTextRoot {\n  color: black;\n  font-family: Space Mono, sans-serif !important;\n}\n\n.cardContentRoot{\n  // background-color:;\n  height: 100%;\n  font-family: Space Mono, sans-serif !important; \n  padding: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_card__7WVYK",
	"noHover": "card_noHover__5tjH4",
	"textRoot": "card_textRoot__-4wkY",
	"cardRoot": "card_cardRoot__CFJgC",
	"countUpTextRoot": "card_countUpTextRoot__sGdzt",
	"cardContentRoot": "card_cardContentRoot__a9Wff"
};
export default ___CSS_LOADER_EXPORT___;
