import React, { ReactElement } from "react";
import { Navigate, Route } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactElement;
  redirectPath: string;
}

const ProtectedRoutes: React.FC<ProtectedRouteProps> = ({
  children,
  redirectPath,
  ...rest
}) => {
  if (localStorage.getItem("authToken")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default ProtectedRoutes;
