// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-error_pageError__x3I0y {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.page-error_refreshButton__ELzXx {\n  border: 1px solid grey;\n  padding: 5px 10px;\n  border-radius: 5px;\n  margin-top: 16px;\n}", "",{"version":3,"sources":["webpack://./src/pages/page-error/page-error.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;;AAGA;EACI,sBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".pageError {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n}\n\n.refreshButton {\n    border: 1px solid grey;\n    padding: 5px 10px;\n    border-radius: 5px;\n    margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageError": "page-error_pageError__x3I0y",
	"refreshButton": "page-error_refreshButton__ELzXx"
};
export default ___CSS_LOADER_EXPORT___;
