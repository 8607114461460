import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import styles from "./grid.module.scss";

interface Props {
  columns: GridColDef[];
  rows: any[];
}

const CommonDataGrid: React.FC<Props> = (props) => {
  const { columns, rows } = props;
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 5 },
        //   },
        // }}
        // pageSizeOptions={[5, 10]}
        // checkboxSelection
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooterPagination
        hideFooter
        hideFooterSelectedRowCount
        sx={{
          boxShadow: 2,
          border: 0,
          // borderColor: "#67dd46",
          // "& .MuiDataGrid-cell:hover": {
          //   color: "primary.main",
          // },
        }}
        classes={{
          withBorderColor: styles.withBorder,
          cellContent: styles.cellContent,
          columnHeaderTitle: styles.columnHeaderTitle,
          cell: styles.cell,
        }}
      />
    </div>
  );
};

export default CommonDataGrid;