import { useState } from "react";
import "./login.css";
import VideoService from "../../services/VideoService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

interface ValidCredentials {
  username: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<ValidCredentials>({
    username: "",
    password: "",
  });
  const [credentialsError, setCredentialsError] = useState<ValidCredentials>({
    username: "",
    password: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>("");
  const [searchParams] = useSearchParams();
  const organization = searchParams.get("organization");
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setLoginError("");
  };
  const handleOnChange = (e: any) => {
    if (e.target.name === "username" && !e.target.value)
      setCredentialsError({
        ...credentialsError,
        username: "Please Enter Username!",
      });
    if (e.target.name === "username" && e.target.value)
      setCredentialsError({
        ...credentialsError,
        username: "",
      });
    if (e.target.name === "password" && !e.target.value)
      setCredentialsError({
        ...credentialsError,
        password: "Please Enter Password!",
      });
    if (e.target.name === "password" && e.target.value)
      setCredentialsError({
        ...credentialsError,
        password: "",
      });
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!credentials.username && !credentials.password) {
      setCredentialsError({
        username: "Please Enter Username!",
        password: "Please Enter Password!",
      });
    } else if (!credentials.username) {
      setCredentialsError({
        username: "Please Enter Username!",
        password: "",
      });
    } else if (!credentials.password) {
      setCredentialsError({
        username: "",
        password: "Please Enter Password!",
      });
    } else {
      setCredentialsError({
        username: "",
        password: "",
      });
      setLoading(true);
      try {
        const response = await VideoService.organizationLogin(
          credentials.username,
          credentials.password
        );
        if (response.status === 200 && response.data.token) {
          localStorage.setItem("authToken", response.data.token);
          if (organization) {
            localStorage.setItem("organization", organization);
            navigate(`/dashboard/${organization}`);
          } else {
            localStorage.setItem("organization", credentials.username);

            var url = new URL(response.data.redirectURL as string)
            navigate(url.pathname);
          }
        } else {
          const error = response?.data?.message || "Some thing went wrong!";
          setLoginError(error);
          setOpen(true);
        }
        setLoading(false);
      } catch (e: any) {
        const error = e?.response?.data?.message || "Some thing went wrong!";
        setLoginError(error);
        setLoading(false);
        setOpen(true);
      }
    }
  };
  return (
    <div className="main-container">
      <div className="hero-circles">
        <div className="circle"></div>
      </div>
      <div className="login-container">
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert severity="error">{loginError}</Alert>
        </Snackbar>
        <div className="wrapper">
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>

            <div className="input-box">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={credentials.username}
                onChange={handleOnChange}
                autoFocus
              />
              <i className="bx bxs-user"></i>
              <p>{credentialsError?.username}</p>
            </div>

            <div className="input-box">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={credentials.password}
                onChange={handleOnChange}
              />
              <i className="bx bxs-lock-alt"></i>
              <p>{credentialsError?.password}</p>
            </div>

            <button
              disabled={loading}
              type="submit"
              className={`btn ${loading && "loading-submit"}`}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
