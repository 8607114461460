// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard-page_dashboardPage__n1uO0 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  min-height: 100vh;\n  background-color: #ffffff;\n}\n\n.dashboard-page_boxContainer__DygMl {\n  width: 90%;\n  margin-top: 30px;\n}\n@media (min-width: 900px) {\n  .dashboard-page_boxContainer__DygMl {\n    width: 85%;\n  }\n}\n\n.dashboard-page_boxGridTop__kz21j {\n  display: flex;\n  justify-content: space-between;\n  margin: 8px 0px;\n}\n\n.dashboard-page_typographyRoot__LJpvp {\n  color: black;\n  font-family: Space Mono, sans-serif;\n}\n\n.dashboard-page_loaderContainer__g\\+qYl {\n  height: 100vh;\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n\n.dashboard-page_dataGridContainer__CdpfT {\n  margin-top: 50px;\n}", "",{"version":3,"sources":["webpack://./src/pages/dashboard-page/dashboard-page.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;AACF;AAAE;EAHF;IAII,UAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAGF;;AAAA;EACE,YAAA;EACA,mCAAA;AAGF;;AAAA;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAGF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".dashboardPage {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  min-height: 100vh;\n  background-color: #ffffff;\n}\n\n.boxContainer {\n  width: 90%;\n  margin-top: 30px;\n  @media (min-width: 900px) {\n    width: 85%;\n  }\n}\n\n.boxGridTop {\n  display: flex;\n  justify-content: space-between;\n  margin: 8px 0px;\n}\n\n.typographyRoot {\n  color: black;\n  font-family: Space Mono, sans-serif;\n}\n\n.loaderContainer {\n  height: 100vh;\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n\n.dataGridContainer {\n  margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardPage": "dashboard-page_dashboardPage__n1uO0",
	"boxContainer": "dashboard-page_boxContainer__DygMl",
	"boxGridTop": "dashboard-page_boxGridTop__kz21j",
	"typographyRoot": "dashboard-page_typographyRoot__LJpvp",
	"loaderContainer": "dashboard-page_loaderContainer__g+qYl",
	"dataGridContainer": "dashboard-page_dataGridContainer__CdpfT"
};
export default ___CSS_LOADER_EXPORT___;
