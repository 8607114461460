import styles from "./card.module.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";

interface Props {
  name: string;
  value: number;
  color: string;
  noHover?: boolean;
  onClick?: (param1: string) => {};
  decimals?: number;
}

const CommonCard: React.FC<Props> = (props) => {
  const {
    name,
    value,
    color = "black",
    noHover,
    onClick = () => {},
    decimals,
  } = props;
  return (
    <Card
      style={{ background: color, height: "100%" }}
      className={noHover ? styles.noHover : styles.card}
      onClick={() => onClick(name)}
      classes={{ root: styles.cardRoot }}
    >
      <CardContent classes={{ root: styles.cardContentRoot }}>
        <Typography
          variant="h6"
          component="div"
          classes={{ root: styles.textRoot }}
        >
          {name}
        </Typography>
        <Typography variant="body1" classes={{ root: styles.countUpTextRoot }}>
          <CountUp end={value} decimals={decimals} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CommonCard;