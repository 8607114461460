// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid_cellContent__q8HYj {\n  color: black !important;\n}\n\n.grid_columnHeaderTitle__OXyck {\n  color: black;\n}\n\n.grid_cell__n9PlY {\n  color: black;\n  font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/components/dataGrid/grid.module.scss"],"names":[],"mappings":"AAIA;EACE,uBAAA;AAHF;;AAMA;EACE,YAAA;AAHF;;AAMA;EACE,YAAA;EACA,gBAAA;AAHF","sourcesContent":["// .withBorder {\n//   border-color:black !important;\n// }\n\n.cellContent {\n  color: black !important;\n}\n\n.columnHeaderTitle {\n  color: black;\n}\n\n.cell {\n  color: black;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cellContent": "grid_cellContent__q8HYj",
	"columnHeaderTitle": "grid_columnHeaderTitle__OXyck",
	"cell": "grid_cell__n9PlY"
};
export default ___CSS_LOADER_EXPORT___;
