import styles from "./dashboard-page.module.scss";
// import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Card } from "../../components/card";
import { useEffect, useState } from "react";
import VideoService from "../../services/VideoService";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/loader";
import { DataGrid } from "../../components/dataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";

export function secondsToMinutes(seconds: number): number {
  let minutes = seconds / 60;
  return parseFloat(minutes.toFixed(2));
}

export function changeBooleanString(booleanString: string): string {
  return booleanString ? "Yes" : "No";
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 50,
    sortable: false,
    align: "center",
  },
  {
    field: "person",
    headerName: "Person",
    width: 120,
    sortable: false,
  },
  {
    field: "website",
    headerName: "Website",
    width: 210,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 250,
    sortable: false,
  },
  {
    field: "uuid",
    headerName: "UserID",
    flex: 1,
    minWidth: 150,
    sortable: false,
  },
  {
    field: "watched_seconds",
    headerName: "Watched Minutes",
    sortable: false,
    width: 145,
    align: "center",
    headerAlign: "center",
    renderCell: (param) => <div>{secondsToMinutes(param.value)}</div>,
  },
  // {
  //   field: "visit_count",
  //   headerName: "Views",
  //   width: 100,
  //   align: "center",
  //   headerAlign: "center",
  //   sortable: false,
  // },
  {
    field: "played",
    headerName: "Played",
    sortable: false,
    width: 100,
    align: "center",
    headerAlign: "center",
    renderCell: (param) => <div>{changeBooleanString(param.value)}</div>,
  },
  {
    field: "cta_clicked",
    headerName: "CTA Clicked",
    sortable: false,
    width: 110,
    align: "center",
    headerAlign: "center",
    renderCell: (param) => <div>{changeBooleanString(param.value)}</div>,
  },
  {
    field: 'played_at',
    headerName: 'Last Played At',
    minWidth: 160,
    sortable: false,
    flex: 1,
    renderCell: (params) => {
        if (params.value) {
            let date = new Date(params.value);
            
            // Offset the time to get the local timezone iso string
            let timezoneOffset = date.getTimezoneOffset() * 60000;
            let localISOTime = (new Date(date.getTime() - timezoneOffset)).toISOString().slice(0,16);

            const formattedDate = localISOTime.replace("T"," ");
            return formattedDate;
        } else {
            return "";  // return empty string if params.value is null
        }
    },
},
  {
    field: "played_count",
    headerName: "Play Counts",
    flex: 1,
    minWidth: 50,
    sortable: false,
  },
];

const DashboardPage: React.FC = () => {
  const params = useParams() as { id: string };
  const [cardDetails, setCardDetails] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [cols, setCols] = useState<GridColDef[]>([]);
  const getDashboardDetails = async () => {
    setLoading(true);
    try {
      const response = await VideoService.dashboardDetails(params.id);
      let { totalCTACreated, totalVideosPlayed, watchedMinutes, totalVideos } =
        response?.data;
      setCardDetails([
        {
          name: "Total Videos",
          value: totalVideos || 0,
          color: "rgb(172 251 204)",
          noHover: true,
        },
        // {
        //   name: "Total Views",
        //   value: totalVisits || 0,
        //   color: "rgb(173, 216, 230)",
        //   noHover: true,
        // },
        {
          name: "Video Views",
          value: totalVideosPlayed || 0,
          color: "#fef1e5",
        },
        {
          name: "CTA Clicked",
          value: totalCTACreated || 0,
          color: "#eaf8f4",
        },
        {
          name: "Total Watched Minutes",
          value: watchedMinutes || 0,
          color: "#ffedf9",
          noHover: true,
        },
      ]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    getDashboardDetails();
  }, []);

  // let filterCols = (field: string) => {
  //   return columns.filter((item) => item.field !== field);
  // };
  let filterCols = (fields: string[]) => {
    return columns.filter((item) => !fields.includes(item.field));
  };

  useEffect(() => {
    switch (selectedCard) {
      case "CTA Clicked":
        setCols(filterCols(["cta_clicked", "played_at", "played_count"]));
        break;
      case "Video Views":
        setCols(filterCols(["played"]));
        break;
      default:
        setCols(columns);
    }
  }, [selectedCard]);

  const getDashboardGridData = async (name: string, isData: boolean) => {
    if (isData && name !== "Total Watched Minutes" && name !== "Total Videos" && name !== "Total Views") {
      setGridLoading(true);
      setSelectedCard(name);
      if (name === "CTA Clicked") {
        try {
          const response = await VideoService.dashboardCtaClicked(params.id);
          let data = response?.data || [];
          // @ts-ignore
          let newData = data?.map((item, index) => {
            return { ...item, id: index + 1 };
          });
          setRows(newData || []);
          setGridLoading(false);
        } catch (e) {
          setGridLoading(false);
          console.log(e);
        }
      } else if (name === "Video Views") {
        try {
          const response = await VideoService.videoPlayed(params.id);
          let data = response?.data || [];
          // @ts-ignore
          let newData = data?.map((item, index) => {
            return { ...item, id: index + 1 };
          });
          setRows(newData || []);
          setGridLoading(false);
        } catch (e) {
          console.log(e);
          setGridLoading(false);
        }
      }
    }
  };

  const handleResetData = async () => {
    setLoading(true);
    try {
      const response = await VideoService.resetOrganizationData(params.id);
      getDashboardDetails();
      setSelectedCard("");
      setRows([]);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className={styles.dashboardPage}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Box className={styles.boxContainer}>
          <Grid container spacing={2}>
            {cardDetails?.map((detail, index) => (
              <Grid key={"card" + index} item xs={12} sm={6} md>
                <Card
                  name={detail?.name}
                  value={detail?.value}
                  color={detail?.color}
                  noHover={detail?.noHover || !detail?.value}
                  onClick={(name) => getDashboardGridData(name, detail?.value)}
                  decimals={detail?.name === "Total Watched Minutes" ? 2 : 0}
                />
              </Grid>
            ))}
          </Grid>
          <div className={styles.dataGridContainer}>
            <Box className={styles.boxGridTop}>
              <Typography
                variant="h6"
                component="h2"
                classes={{ root: styles.typographyRoot }}
              >
                {selectedCard}
              </Typography>
              <Button
                sx={{
                  textTransform: "capitalize",
                  background:
                    "linear-gradient(blue)",
                  fontFamily: "Space Mono, sans-serif",
                  color: "white",
                  fontWeight: 600,
                }}
                variant="contained"
                onClick={handleResetData}
              >
                Reset Data
              </Button>
            </Box>
            {gridLoading ? (
              <Loader />
            ) : (
              <>
                {rows?.length !== 0 ? (
                  <DataGrid columns={cols} rows={rows} />
                ) : null}
              </>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default DashboardPage;