import React, { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface PrivateRouteProps {
  children: ReactElement;
  redirectPath: string;
}

const PrivateRoutes: React.FC<PrivateRouteProps> = ({
  children,
  redirectPath,
  ...rest
}) => {
  const location = useLocation();
  if (!localStorage.getItem("authToken")) {
    return <Navigate to={redirectPath} replace />;
  }
  if (
    localStorage.getItem("authToken") &&
    location?.pathname === "/dashboard/:id"
  ) {
    return (
      <Navigate
        to={`/dashboard/${localStorage.getItem("organization")}`}
        replace
      />
    );
  }
  return children;
};

export default PrivateRoutes;
