import { useEffect, useState } from 'react';
import styles from './video-page.module.scss';
import { VideoPlayer } from '../../components/video-player';
import VideoService from '../../services/VideoService';
import { Loader } from '../../components/loader';
import { useLocation, useParams } from 'react-router-dom';

const VideoPage: React.FC = () => {
    const [meeting_link, setMeeting_link] = useState('');
    const [person, setPerson] = useState('');
    const [logo_url, setLogo_url] = useState('');
    const [video_link, setVideo_link] = useState('');
    const [videoUUID, setVideoUUID] = useState('');

    const params = useParams() as { clientName: string, id: string };

    useEffect(() => {
        getVideo();
    }, []);

    useEffect(() => {
        if(person !== undefined) {
          document.title = `Nice to meet you, ${person}!`;
        }
        const script = document.createElement('script');
        let noscript: HTMLElement | null = null;
        
        if (params.clientName === 'bigteams') {
            loadScript();
        }

        // Create a new function loadScript
        function loadScript() {
            const script = document.createElement('script');
            script.innerHTML = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '354935580925896');
                fbq('track', 'PageView');`;
            document.body.appendChild(script);

            // Add noscript img for Facebook as well
            const noScript = document.createElement("noscript");
            const img = document.createElement("img");
            img.height = 1;
            img.width = 1;
            img.style.display = "none";
            img.src = "https://www.facebook.com/tr?id=354935580925896&ev=PageView&noscript=1";

            noScript.appendChild(img);
            document.body.appendChild(noScript);
        }

        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.async = true;
        
        script.onload = () => {
            if (params.clientName === 'hotelengine') {
                const hubspot = window as any; 
                hubspot.hbspt.forms.create({
                    target: '#hubspotForm',
                    region: "na1",
                    portalId: "7770829",
                    formId: "3d0c6564-1be4-40e7-9f6f-a8c2d07553af",
                    onFormSubmit: function() {
                        ctaClick();
                    }
                });
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            if (noscript) {
                document.body.removeChild(noscript);
            }
        }
    }, [params.clientName, person]);

    const getButtonClass = (clientName:string) => {
        return clientName === 'bigteams' ? styles.bigteamsButton : styles.button;
    };

//     const getButtonClass = (clientName:string) => {
//         return clientName === 'homebolt' ? styles.bigteamsButton : styles.button;
//     };

    async function getVideo() {
        try {
            const response = await VideoService.fetchVideo(params.clientName, params.id);
            setMeeting_link(response.data.meeting_link);
            setPerson(response.data.person);
            setLogo_url(response.data.logo_url);
            setVideo_link(response.data.video_link);
            setVideoUUID(response.data.uuid);
        } catch (e) {
            console.log(e);
        }
    }

    async function ctaClick() {
        try {
            const response = await VideoService.ctaClicked(videoUUID);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        // <div className={styles.videoPage}>
        <div className={`${styles.videoPage} ${params.clientName === 'homebolt' ? styles.homeboltBackground : ''}`}>
            <div className={styles.logoContainer}>
                <img src={logo_url} alt="logo" />
            </div>
            <h1 className={styles.title}>Nice to meet you {person}!</h1>
            {
                video_link ? <VideoPlayer video_link={video_link} uuid={videoUUID} clientName={params.clientName} />
                    : <div className={styles.space}>
                        <Loader />
                    </div>
            }
            {/* <h2 className={styles.subtitle}>Schedule a 30 min meeting to learn more.</h2> */}
            {/* {params.clientName !== 'hotelengine' ? 
            <h2 className={styles.subtitle}>Schedule a 30 min meeting to learn more.</h2> : null }
            {params.clientName === 'hotelengine' ? 
                <div id='hubspotForm'></div> :
                <a
                    href={meeting_link}
                    target='_blank'
                    className={getButtonClass(params.clientName)}
                    onClick={ctaClick}
                >
                    Schedule a meeting
                </a>
            }         */}
            {params.clientName === 'hotelenginecole' ? (
                <h2 className={styles.subtitle}>
                    Click the button below to add in your trip details and we'll send you some hotel options for free.
                </h2>
                ) : params.clientName === 'homebolt' ? (
                <h2 className={styles.homeboltsubtitle}>
                    Schedule a Call or Property Walk
                </h2>
                ) : params.clientName === 'internal_digital' ? (
                <h2 className={styles.subtitle}>
                    Schedule a 15 min meeting to learn more.
                </h2>
                ) : params.clientName !== 'hotelengine' ? (
                <h2 className={styles.subtitle}>
                    Schedule a 30 min meeting to learn more.
                </h2>
                ) : null}
            {params.clientName === 'hotelengine' ?
                <div id='hubspotForm'></div> :
                params.clientName === 'hotelenginecole' ?
                <a
                    href={meeting_link}
                    target='_blank'
                    className={getButtonClass(params.clientName)}
                    onClick={ctaClick}
                >
                    Tell us about your trip
                </a> :
                params.clientName === 'homebolt' ?
                <a
                    href={meeting_link}
                    target='_blank'
                    className={getButtonClass(params.clientName)}
                    onClick={ctaClick}
                >
                    Schedule a Meeting
                </a> :
                <a
                    href={meeting_link}
                    target='_blank'
                    className={getButtonClass(params.clientName)}
                    onClick={ctaClick}
                >
                    Schedule a meeting
                </a>
            }
            
        </div>
    );
};

export default VideoPage;
