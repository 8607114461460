import "./App.css";
import styles from "./app.module.scss";
import { VideoPage } from "./pages/video-page";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { DashboardPage } from "./pages/dashboard-page";
import { LoginPage } from "./pages/login-page";
import ProtectedRoutes from "./routes/protectedRoutes";
import PrivateRoutes from "./routes/privateRoutes";
import React, { useEffect } from 'react';
import VideoService from './services/VideoService';


const App: React.FC = () => {

  const Redirector: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (id) { // Checking if 'id' is defined before making the api call
        VideoService.fetchVideoById(id)
          .then((res) => {
            // Checking if 'video_link' contains 'internal'
            console.log(res)
            if (res.data.video_link && res.data.video_link.includes("internal")) {
              navigate(`/share/internal/${id}`);
            } else {
              const linkParts = res.data.video_link.split('/');
              const clientName = linkParts[linkParts.length - 2]
              navigate(`/share/${clientName}/${id}`);
            }
          })
          .catch((err) => console.error(err));
      }
    }, [id, navigate]);

    return null;
  }

  return (
    <div className={styles.app}>
      <Routes>
        <Route path="share/:id" element={<Redirector />} />
        <Route path="share/:clientName/:id" element={<VideoPage />} />
        <Route path="/" element={<Navigate to="login" replace />} />
        <Route
          path="/login"
          element={
            <ProtectedRoutes redirectPath="/dashboard/:id">
              <LoginPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path="dashboard/:id"
          element={
            <PrivateRoutes redirectPath="/login">
              <DashboardPage />
            </PrivateRoutes>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
