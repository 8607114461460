import styles from "./loader.module.scss";

const Loader: React.FC<{ style?: string }> = ({ style }) => {
  return (
    <div className={`${styles.ldsLoader} ${style}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
